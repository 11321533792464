<template>
  <div class="mapbox-wrapper">
    <MglMap
      :access-token="accessToken"
      :map-style.sync="options.style"
      :center="options.center"
      :zoom="options.zoom"
      :pitch="options.pitch"
      :bearing="options.bearing"
      :container="options.container"
      :attribution-control="false"
      @load="onLoad"
    >
      <MglNavigationControl position="bottom-right" />
    </MglMap>

    <div :id="topRightDiv">
      <mapbox-control
        :search-toggle.sync="searchToggle"
        :filter-toggle.sync="filterToggle"
        :grid-toggle.sync="gridToggle"
      />
    </div>

    <b-collapse
      v-model="searchToggle"
      class="searchbox-wrapper"
    >
      <search-box
        :results.sync="results"
        :search-close.sync="searchToggle"
        @searchInput="updateSearchInput"
        @submit="searchLocation"
      />
    </b-collapse>

    <b-collapse
      v-model="filterToggle"
      class="filter-panel-wrapper"
    >
      <filter-panel
        :results.sync="results"
        :filter-close.sync="filterToggle"
      />
    </b-collapse>

    <b-collapse
      v-model="gridToggle"
      class="grid-panel-wrapper"
    >
      <grid-panel
        :grid-close.sync="gridToggle"
      />
    </b-collapse>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import {
  MglMap,
  MglNavigationControl,
} from 'vue-mapbox'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import MapboxControl from './MapboxControl.vue'
import SearchBox from '../search-box/Search.vue'
import FilterPanel from '../filter-panel/index.vue'
import GridPanel from '../grid-panel/index.vue'

export default {
  components: {
    MglMap,
    MglNavigationControl,
    SearchBox,
    FilterPanel,
    GridPanel,
    MapboxControl,
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_ACCESS_TOKEN, // your access token. Needed if you using mapboxgl maps
      options: {
        style: 'mapbox://styles/mapbox/light-v10',
        center: [55.2752798, 25.1919927],
        zoom: 15.5,
        pitch: 65,
        bearing: -17.6,
        container: 'map',
        antialias: true,
        marker: false,
      }, // your map style
      geocoder: null,

      topRightDiv: 'top-right-components',
      searchToggle: false,
      filterToggle: false,
      gridToggle: false,
      results: [],
    }
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = mapboxgl
  },
  methods: {
    onLoad({ map, component }) {
      // Insert the layer beneath any symbol layer.
      const { layers } = map.getStyle()
      const labelLayerId = layers.find(
        layer => layer.type === 'symbol' && layer.layout['text-field'],
      ).id

      // The 'building' layer in the mapboxgl Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      map.addLayer(
        {
          id: 'add-3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 14,
          paint: {
            'fill-extrusion-color': '#aaa',
            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              14,
              0,
              15.05,
              ['get', 'height'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              14,
              0,
              15.05,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.6,
          },
        },
        labelLayerId,
      )

      // Add full screen control
      map.addControl(new this.mapbox.FullscreenControl(), 'bottom-right')

      // Add top right controls
      document.getElementsByClassName('mapboxgl-ctrl-top-right')[0].appendChild(document.getElementById(this.topRightDiv))

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
      })

      // Bind to custom search field
      geocoder.on('results', this.handleSearch)
      this.geocoder = geocoder

      // Add search box field
      document.getElementById('search-field').appendChild(this.geocoder.onAdd(map))

      // Map resize
      map.resize()
    },
    handleSearch(geocoder) {
      this.results = geocoder?.features
    },
    updateSearchInput(val) {
      if (val.length === 0) this.results.splice(0)

      this.geocoder.setInput(val)
    },
    searchLocation(val) {
      this.geocoder.query(val)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.mapbox-wrapper {
  position: absolute;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  width: 100%;
}
.searchbox-wrapper {
  position: absolute;
  top: 186px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 960px;
  margin: auto;
  z-index: 9;
}
::v-deep .mapboxgl-ctrl-geocoder {
  display: none !important;
}
::v-deep .mapboxgl-ctrl-top-right {
  z-index: 10;
}
.filter-panel-wrapper {
  position: absolute;
  top: 106px;
  right: 10px;
  width: 100%;
  max-width: 322px;
  margin: auto;
  z-index: 9;
}
.grid-panel-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 322px;
  margin: auto;
  z-index: 9;
  padding-top: 106px;
  padding-right: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: #4B4B4B;
    opacity: 0.65;
    filter: blur(100px);
  }

  > * {
    position: relative;
  }
}
</style>
