<template>
  <b-card
    no-body
    text-variant="white"
    class="overflow-hidden"
  >
    <b-card-header
      header-bg-variant="success"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          size="24"
          class="mr-1"
          icon="FilterIcon"
        />
        <b-card-title class="text-white">
          Filter Data
        </b-card-title>
      </div>

      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click="closeFilter"
      />
    </b-card-header>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="filter-panels scroll-area"
    >
      <div
        v-for="(item, index) in limitedFilter"
        :key="`filter-${index}`"
        class="filer-panel"
      >
        <!-- Parent Items -->
        <b-card-header
          header-bg-variant="dark"
        >
          <div class="d-flex align-items-center">
            <feather-icon
              size="24"
              class="mr-1"
              :icon="item.icon"
            />
            <b-card-title class="text-white">
              {{ item.title }}
            </b-card-title>
          </div>

          <b-form-checkbox
            v-if="item.switch"
            v-model="item.active"
            class="custom-control-success"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-card-header>
        <!-- Child Items -->
        <div class="filter-panel-children">
          <b-card-body
            v-for="(childItem, index2) in item.children"
            :key="`filter-child-${index2}`"
          >
            <div class="d-flex justify-content-between align-items-center pl-3">
              <b-media
                no-body
                :class="{ 'in-active': !childItem.active }"
              >
                <feather-icon
                  size="24"
                  class="mr-1"
                  :icon="childItem.icon"
                />
                <h6 class="align-self-center my-auto">
                  {{ childItem.title }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="childItem.active"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-card-body>
          <b-card-body
            v-if="filter[index].children.length > item.limit"
            class="pt-0"
          >
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                size="sm"
                @click="limitToggle(index)"
              >
                <h5 class="text-success m-0">Show More</h5>
              </b-button>
            </div>
          </b-card-body>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </b-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    results: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      filter: [
        {
          limit: 3,
          slug: 'sector',
          title: 'Sector',
          icon: 'ArchiveIcon',
          switch: true,
          active: true,
          children: [
            {
              slug: 'transportation',
              title: 'Transportation',
              icon: 'TruckIcon',
              active: true,
            },
            {
              slug: 'telecom',
              title: 'Telecom',
              icon: 'PhoneCallIcon',
              active: false,
            },
            {
              slug: 'energy',
              title: 'Energy',
              icon: 'ZapIcon',
              active: false,
            },
            {
              slug: 'transportation',
              title: 'Transportation',
              icon: 'TruckIcon',
              active: true,
            },
          ],
        },
        {
          limit: 2,
          slug: 'data-state',
          title: 'Date State',
          icon: 'DatabaseIcon',
          switch: true,
          active: true,
          children: [
            {
              slug: 'real-time',
              title: 'Real-Time',
              icon: 'CheckCircleIcon',
              active: true,
            },
            {
              slug: 'historical',
              title: 'Historical',
              icon: 'RewindIcon',
              active: false,
            },
          ],
        },
        {
          limit: 1,
          slug: 'variation',
          title: 'Variation',
          icon: 'ShuffleIcon',
          switch: false,
          active: false,
          children: [
            {
              slug: 'timeline-widget',
              title: 'Timeline Widget',
              icon: 'ClockIcon',
              active: false,
            },
            {
              slug: 'real-time',
              title: 'Real-Time',
              icon: 'CheckCircleIcon',
              active: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    limitedFilter() {
      return this.filter.reduce((acc, item, index) => {
        acc[index] = { ...item, children: item.children.filter((e, i) => i < item.limit) }
        return acc
      }, [])
    },
  },
  methods: {
    closeFilter() {
      this.$emit('update:filterClose', false)
    },
    limitToggle(index) {
      this.$set(this.filter[index], 'limit', this.filter[index].limit + 2)
    },
  },
}
</script>

<style lang="scss" scoped>
.card .card-header,
.card .card-body {
  padding: 1rem;
}
.filter-panels {
  max-height: 50vh;
}
.media.in-active {
  opacity: 0.5;
}
</style>
