<template>
  <div class="searchbox">
    <b-row>
      <b-col cols="12">
        <div id="search-field" class="position-relative">
          <b-form-input
            v-model="searchInput"
            @input="searching"
            @blur="blurInput"
            @focus="focusInput"
            autocomplete="off"
            size="lg"
            placeholder="Search Location"
            class="form-input-white search-location"
          />
          <b-list-group v-show="showList">
            <b-list-group-item
              v-for="(result, index) in results"
              :key="`result-${index}`"
              button
              @click="submit(result.place_name)"
            >
              {{ result.place_name }}
            </b-list-group-item>
            <b-list-group-item
              v-if="results.length === 0"
            >
              No Result Found.
            </b-list-group-item>
          </b-list-group>
          <b-button
            variant="dark"
            class="btn-icon btn-cancel-icon btn-sm"
            @click="closeSearch"
          >
            <feather-icon
              icon="XIcon"
              size="1.5x"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    results: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchInput: '',
      showList: false,
    }
  },
  methods: {
    blurInput() {
      setTimeout(() => { this.showList = false }, 200)
    },
    focusInput() {
      setTimeout(() => { this.showList = true }, 200)
    },
    closeSearch() {
      this.blurInput()
      this.$emit('update:searchClose', false)
    },
    searching() {
      if (this.searchInput.length < 2) this.blurInput()
      else this.focusInput()

      this.$emit('searchInput', this.searchInput)
    },
    submit(val) {
      this.searchInput = val

      this.$emit('submit', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn.btn-cancel-icon {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
}
</style>
