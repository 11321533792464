<template>
  <div class="d-flex flex-column align-items-end">
    <div class="mb-2">
      <a href="" class="d-flex align-items-center text-white">
        Export
        <img
          :src="require('@/assets/images/icons/upload.svg')"
          class="ml-2"
        />
      </a>
    </div>
    <div class="mb-2">
      <a href="" class="d-flex align-items-center text-white">
        Snapshot
        <img
          :src="require('@/assets/images/icons/aperture.svg')"
          class="ml-2"
        />
      </a>
    </div>
    <div class="mb-2">
      <a href="" class="d-flex align-items-center text-white">
        Record Video
        <img
          :src="require('@/assets/images/icons/video.svg')"
          class="ml-2"
        />
      </a>
    </div>
    <div class="mb-2">
      <a href="" class="d-flex align-items-center text-white">
        Share
        <img
          :src="require('@/assets/images/icons/share-2.svg')"
          class="ml-2"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    closeGrid() {
      this.$emit('update:gridClose', false)
    },
  },
}
</script>
