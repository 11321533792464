<template>
  <div class="header-stats position-relative">
    <b-container>
      <!-- -->
      <b-collapse
        id="collapse-info-stats"
      >
        <div class="d-flex">
          <div class="flex-grow-1">
            <b-row class="mx-0 my-05">
              <b-col class="text-warning px-05" cols="12">
                Overall Connectivity Stats
              </b-col>
            </b-row>
            <b-row class="mx-0">
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">12,933</b-card-text>
                  <b-card-text class="text-warning text-sm text-truncate mb-0">
                    Properties
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">32</b-card-text>
                  <b-card-text class="text-warning text-sm text-truncate mb-0">
                    Communities
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">4</b-card-text>
                  <b-card-text class="text-warning text-sm text-truncate mb-0">
                    Industries
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">5</b-card-text>
                  <b-card-text class="text-warning text-sm text-truncate mb-0">
                    Use Cases
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="flex-grow-1">
            <b-row class="mx-0 my-05">
              <b-col class="text-success px-05" cols="12">
                Use-Case Connectivity Stats
              </b-col>
            </b-row>
            <b-row class="mx-0">
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">12,933</b-card-text>
                  <b-card-text class="text-success text-sm text-truncate mb-0">
                    Properties
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">32</b-card-text>
                  <b-card-text class="text-success text-sm text-truncate mb-0">
                    Communities
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col class="px-05">
                <b-card
                  class="w-100 cursor-pointer text-center mb-2"
                >
                  <b-card-text class="text-white mb-0">4</b-card-text>
                  <b-card-text class="text-success text-sm text-truncate mb-0">
                    Industries
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-collapse>
      <!-- -->
      <div class="d-flex flex-column flex-wrap align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <h2 class="text-white">Sustainability Case - Downtown Dubai</h2>
          <b-button
            v-b-toggle.collapse-info-stats
            variant="flat-warning"
            class="btn-icon btn-icon-custom text-white"
          >
            <feather-icon
              class="when-opened"
              size="2.5x"
              icon="ChevronsUpIcon"
            />
            <feather-icon
              class="when-closed"
              size="2.5x"
              icon="ChevronsDownIcon"
            />
          </b-button>
        </div>
        <b-card-text class="mb-3">
          <div class="d-flex flex-wrap align-items-center">
            <b-form-radio
              v-model="Selected"
              name="some-radio9"
              value="city"
              class="custom-control-white mr-3"
            >
              City Level
            </b-form-radio>
            <b-form-radio
              v-model="Selected"
              name="some-radio9"
              value="district"
              class="custom-control-white mr-3"
            >
              District Level
            </b-form-radio>
            <b-form-radio
              v-model="Selected"
              name="some-radio9"
              value="asset"
              class="custom-control-white mr-3"
            >
              Asset Level
            </b-form-radio>
          </div>
        </b-card-text>
      </div>
    </b-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      Selected: 'district',
      infoOpen: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.custom-control-white ::v-deep .custom-control-label {
  cursor: pointer;
}
.custom-control-white ::v-deep .custom-control-input:not(:checked) ~ .custom-control-label::before {
  border-color: $light;
  background-color: $light;
}
.custom-control-white ::v-deep .custom-control-input:checked ~ .custom-control-label {
  color: #fff;
}
.custom-control-white ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
  background-color: #fff;
}
.btn-icon-custom {
  background-color: transparent !important;
}
.btn-icon-custom.not-collapsed {
  color: $warning !important;
}

.header-stats {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#283046+0,283046+100&0.9+23,0.85+37,0+100 */
  background: -moz-linear-gradient(top,  rgba(40,48,70,0.9) 0%, rgba(40,48,70,0.9) 23%, rgba(40,48,70,0.85) 37%, rgba(40,48,70,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(40,48,70,0.9) 0%,rgba(40,48,70,0.9) 23%,rgba(40,48,70,0.85) 37%,rgba(40,48,70,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(40,48,70,0.9) 0%,rgba(40,48,70,0.9) 23%,rgba(40,48,70,0.85) 37%,rgba(40,48,70,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6283046', endColorstr='#00283046',GradientType=0 ); /* IE6-9 */
}
.header-stats .container {
  max-width: 865px;
}
.card-body {
  padding: 0.5rem;
}
</style>
