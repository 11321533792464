<template>
  <div class="circular-menu" @click="slideTo">
    <carousel
      :loop="true"
      :rewind="false"
      :center="true"
      :mouse-drag="false"
      :touch-drag="false"
      :pull-drag="false"
      :margin="10"
      :items="5"
      class="nav-carousel"
    >
      <div
        v-for="(item, index) in nav"
        :key="`navlink-${index}`"
      >
        <button
          class="nav-link"
        >
          <icon
            :icon-name="item.icon"
          />
          {{ item.title }}
        </button>
      </div>
    </carousel>

    <menu-path />
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Icon from './icons/index.vue'
import MenuPath from './icons/path.vue'

export default {
  components: {
    Icon,
    MenuPath,
    carousel,
  },
  data() {
    return {
      nav: [
        {
          title: 'Mobility',
          icon: 'mobility',
          link: '',
        },
        {
          title: 'IoT BMS',
          icon: 'iot',
          link: '',
        },
        {
          title: 'Sustainability',
          icon: 'sustainability',
          link: '',
        },
        {
          title: 'Retrofitting',
          icon: 'retrofitting',
          link: '',
        },
        {
          title: 'Recycling',
          icon: 'recycling',
          link: '',
        },
      ],
    }
  },
  mounted() {
    document.querySelectorAll('.owl-item .nav-link').forEach((e, i) => {
      e.setAttribute('data-index', i)
    })
  },
  methods: {
    slideTo(e) {
      const index = e.target.getAttribute('data-index')
      Object.values(document.getElementsByClassName('owl-carousel')[0]).find(e => e['owl.carousel'])['owl.carousel'].to(index)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.circular-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  overflow: hidden;
  padding-top: 100px;
  text-align: center;
  pointer-events: none;
}
.nav-carousel {
  width: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  pointer-events: auto;
}
.nav-carousel:before {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  right: -20%;
  height: 300px;
  border-radius: 100%;
  background: #4B4B4B;
  filter: blur(30px);
  z-index: -1;
}
::v-deep .owl-stage {
  display: flex;
  align-items: flex-end;
}
::v-deep .owl-item {
  transition: all ease 0.3s;
  top: 30px;
}
::v-deep .owl-item.active {

  &:not(.center),
  &.center + .owl-item.active + .owl-item.active {
    top: 30px;
  }

  &:not(.center) + .owl-item.active:not(.center),
  &.center + .owl-item.active {
    top: 10px;
  }
}
::v-deep .owl-item.active.center {
  top: 0;
}

.nav-carousel .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $light;
  padding-left: 0;
  padding-right: 0;
  width: 100px;
  margin: auto;
  border: 0;
  background: transparent;
}
::v-deep .nav-carousel .owl-item.active.center .nav-link {
  color: #fff;
  font-weight: bold;
}
::v-deep .nav-carousel .owl-item.active.center .icon {
  color: #fff;
}
</style>
