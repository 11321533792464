<template>
  <b-row class="top-right-controls-row">
    <b-col>
      <b-button
        variant="warning"
        class="btn-icon btn-white-icon btn-sm"
        :pressed="searchToggle"
        @click="clicked('search')"
      >
        <feather-icon
          icon="SearchIcon"
          size="1.5x"
        />
      </b-button>
    </b-col>
    <b-col>
      <b-button
        variant="warning"
        class="btn-icon btn-white-icon btn-sm"
        :pressed="filterToggle"
        @click="clicked('filter')"
      >
        <feather-icon
          icon="FilterIcon"
          size="1.5x"
        />
      </b-button>
    </b-col>
    <b-col>
      <b-button
        variant="warning"
        class="btn-icon btn-white-icon btn-sm"
        :pressed="gridToggle"
        @click="clicked('grid')"
      >
        <feather-icon
          icon="GridIcon"
          size="1.5x"
        />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>

export default {
  props: {
    searchToggle: {
      type: Boolean,
      default: false,
    },
    filterToggle: {
      type: Boolean,
      default: false,
    },
    gridToggle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked(val) {
      this.$emit('update:searchToggle', (val === 'search' ? !this.searchToggle : false))
      this.$emit('update:filterToggle', (val === 'filter' ? !this.filterToggle : false))
      this.$emit('update:gridToggle', (val === 'grid' ? !this.gridToggle : false))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.top-right-controls-row {
  margin-top: 56px;
  margin-left: 0;
  margin-right: 0;
  pointer-events: auto;
}
.top-right-controls-row > .col {
  padding-left: 10px;
  padding-right: 10px;
}
.btn-white-icon:not(:hover):not(:disabled) {
  background-color: #fff !important;
  border-color: #fff !important;
  color: $dark !important;
}
.btn-white-icon:not(:hover):not(:disabled).active {
  background-color: $warning !important;
  border-color: $warning !important;
  color: #fff !important;
}
</style>
